<template>
    <div class="create-alert-page">
        <AlertMenu />
        <CreateEditAlert @alertChanged="handleChange" :is-create="true" />
        <Dialog modal :visible.sync="showWarningModal" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    UNSAVED CHANGES
                </h1>
                <Divider />
                <p class="modal-content">
                    Exiting now will discard any changes made to your alert settings. Please save your changes to ensure your alert preferences are updated.
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="exit">
                        Exit Anyway
                    </Button>
                    <Button class="btn orange-bg" @click="continueEdit">
                        Continue Editing
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
import AlertMenu from '../../components/AlertMenu.vue'
import CreateEditAlert from '../../components/CreateEditAlert.vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
    name: "CreateAlert",
    components: {
        AlertMenu,
        CreateEditAlert,
        Dialog,
        Divider,
        Button
    },
    data(){
        return{
            apiURL,
            user: {},
            showWarningModal: false,
            changed: false,
        }
    },
    beforeRouteLeave (to, from , next) {
        this.exit = () => {
            next();
        }
        this.continueEdit = () => {
            next(false);
            this.showWarningModal=false;
        }
        if(this.changed){
            this.showWarningModal = true;
        }else{
            next();
        }
    },
    methods:{
        exit(){
        },
        continueEdit(){
        },
        handleChange(changed){
            this.changed = changed;
        }
    },
    metaInfo: {
        title: 'Create your alert',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
}
</style>